import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Heading,
  Row,
  Column,
  Image,
  Section,
  Text,
  UList,
} from 'components'
import Tips from 'images/blogs/blog-2.jpg'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/mga-tips-sa-paggamit-ng-digipay-app`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Mga Tips Sa Paggamit Ng Digipay App',
  },
  {
    property: 'og:description',
    content: 'Ito na ang mga tips sa paggamit ng Digipay!',
  },
  {
    property: 'og:image',
    content: Tips,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="Mga Tips sa Paggamit ng Digipay App"
      meta={SITE_META}
      fontWeight="bold"
    >
      <html lang="en" />
    </Helmet>
    <Row flexJustify="center" m="30px">
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3">
        <Image width="100%" mb="sp3" src={Tips} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          MGA TIPS SA PAGGAMIT NG DIGIPAY APP
        </Heading>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Thumbs Up Kapag Early Top-Up
          </Heading>
          <Text lineHeight="1.5">
            Wag kalimutang mag-top-up before the weekend. Siguraduhing laging
            may laman ang Digipay wallet para tuloy-tuloy ang pagtransact ng
            bills payment, e-load, e-pins, pera padala, at microinsurance. Dahil
            “first in, first out” ang bagong top-up feature, iwasan ang last
            minute top-up para may oras kapa habang pina-process ang iyong
            deposit confirmation at para hindi tumigil ang iyong business
            sakaling maubusan ka ng Digipay credits.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Transaction Error No More
          </Heading>
          <Text lineHeight="1.5">
            Wala ka ng oras na masasayang sa maling pagtransact. Tandaan na
            dapat laging updated ang iyong app at may malakas na internet
            connection sa iyong lugar para maiwasan ang ‘transaction error’.
            Tignan sa ibaba ang ilan pang tips sa pagtransact:
            <UList ml="sp3" className="blog__list">
              <li>
                Kumpletuhin ang mga ilalagay na detalye sa bawat transaction
                fields
              </li>
              <li>
                Ang area code ay dapat two digits lang (PLDT). Halimbawa: Ang
                Cebu area code ay 032. Ang dapat ilagay sa Digipay app ay 32
                lang.
              </li>
              <li>
                May ibang electric company billers na nagre-require ng maximum
                of 30 characters sa paglagay ng ‘name’ at ‘surname’. Dapat ay
                wala ring special characters na kasama sa pangalan
              </li>
              <li>
                Siguraduhing exact amount ng babayaran ang ilalagay sa bawat
                transaction
              </li>
            </UList>
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Sure ang Live Chat Support
          </Heading>
          <Text lineHeight="1.5">
            May tanong ka ba tungkol sa Digipay? Masasagot agad yan ng aming
            Sales Support Team dahil may live chat support ang iyong Digipay app
            mula Lunes hanggang Sabado (9AM-6PM). Kami na mismo ang magpaliwanag
            sa’yo ng mga dapat mong malaman tungkol sa paggamit ng Digipay app
            at hindi mo na kailangang maghintay o magtanong sa kapwa agent mo.
            Ang dali ‘di ba?
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Belong Ka Sa Digipay Agent Group
          </Heading>
          <Text lineHeight="1.5">
            Maging alert sa lahat ng app o promo updates at mag-join na ng
            ‘DIGIPAY Agent Group’ sa Facebook. Dito mo makikita ang lahat ng mga
            announcement na para lamang sa mga Digipay agents. May system
            maintenance alerts din kaya malalaman mo kung kailan at anong oras
            hindi maaaring magtransact. Kung hindi ka pa member ng group,
            i-search lang ang name na ‘DIGIPAY Agent Group’ at i-click ang
            ‘Join’.
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)
export default ReasonsWhy
